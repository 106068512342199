.services {
  text-align: center;

  .service {
    width: 33.333%;
    float: left;
    padding: 30px;
    cursor: pointer;
    transition: background-color $standard-transition;

    i {
      @include font_size(icon4x);
      display: block;
    }

    .service_title {
      @include font_size(md);
    }

    .service_text {
      @include font_size(sm);
    }

    &:hover {
      .service_text {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .panel_body {
    @include clearfix();
  }
}
