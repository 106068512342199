.members {
  .member {
    width: 33.333%;
    float: left;
    background-size: cover;
    min-height: 400px;
    background-position: center center;
    position: relative;
    font-weight: 300;

    .member_name {
      background-color: scheme(1);
      padding: 20px;
      display: inline-block;
      position: relative;
    }

    .member_speciality {
      background-color: scheme(1);
      padding: 20px;
      display: inline-block;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    &:before {
      content: '';
      @include full();
      background-color: rgba($black, 0);
      transition: background-color $standard-transition;
    }

    &:hover {
      &:before {
        background-color: rgba($black, .4);
      }
    }

    @include media-breakpoint-down(md) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  @include clearfix();
}
