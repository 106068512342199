.panel_body {
	position: relative;
	max-width: 1400px;
	margin: 0 auto;
	padding: 50px;
	z-index: 1;

	&.fullwidth {
		max-width: none;
		padding: 0;
	}
	&.panel_half {
		width: 49%;
		display: inline-block;

		@include media-breakpoint-down(md) {
			width: 49%;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

}
